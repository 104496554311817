
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import DragBar from "./form/DragBar.vue";
import ColorDragBar from "./form/ColorDragBar.vue";
import { createChangeHandlers } from "./form/utils";
import Toggle from "./form/Toggle.vue";

export default {
    components: { ColorDragBar, DragBar, Toggle, Editor, item: FormItem },
    setup() {
        return {
            ...createChangeHandlers([
                "radius",
                "fill",
                "fillColor",
                "strokeWidth",
                "strokeColor",
                "shadowBlur",
                "shadowColor",
            ]),
        };
    },
};
