
import { computed, Ref, ref } from "vue";
import DragBar from "./DragBar.vue";
import Toggle from "./Toggle.vue";

export default {
    props: {
        name: { type: String, default: "" },
        type: { type: String, default: "" },
        config: { type: Object, default: () => ({}) },
    },
    setup(props: any) {
        return {};
    },
};
