<template compiler="vugel">
    <text
        :x="10"
        :y="10"
        :color="0xffffffff"
        :font-size="60"
        :font-weight="900"
        font-style="italic"
        font-face="Serif"
        :margin-bottom="20"
    >
        basic text rendering
    </text>
</template>

<script lang="ts">
export default {};
</script>
