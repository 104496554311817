
import { Vugel } from "vugel";
import Examples from "./Examples.vue";
import { ref, Ref, computed, reactive } from "vue";

export default {
    components: { Vugel, Examples },
    setup() {
        const examples = ref([
            { name: "picture", text: "Picture" },
            { name: "rectangle", text: "Rectangle" },
            { name: "text", text: "Text" },
            { name: "styled-rectangle", text: "Styled rectangle" },
            { name: "drawing", text: "Drawing" },
            { name: "position", text: "Position and size" },
            { name: "center", text: "Center" },
            { name: "transforms", text: "Transforms" },
            { name: "visibility", text: "Visibility" },
            { name: "tinting", text: "Tinting" },
            { name: "clipping", text: "Clipping" },
            // { name: "texture-clipping", text: "Texture clipping" },
            // { name: "z-index", text: "Z-index" },
            // { name: "flexbox", text: "Flexbox" },
            // { name: "effects", text: "Effects" },
            { name: "paragraph", text: "Paragraph" },
            // { name: "mouse-events", text: "Mouse events" },
            // { name: "focus-events", text: "Focus events" },
            // { name: "intersection", text: "Intersection" },
            { name: "particles", text: "Particles" },
            { name: "teleport", text: "Teleport" },
            { name: "direct", text: "Direct" },
            { name: "many-components", text: "Many components performance" },
        ]);
        const example: Ref<string> = ref("picture");
        const check = (exampleName: string) => {
            return example.value === exampleName;
        };
        const exampleObject = computed(
            () => examples.value.find((obj: any) => obj.name === example.value) || { name: "", text: "" },
        );
        const myvar = ref(1);
        const myobj = reactive({x: 0, y: 0});
        return { examples, example, check, exampleObject, myvar, myobj };
    },
};
