
import DragBar from "./form/DragBar.vue";
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import { createChangeHandlers } from "./form/utils";

export default {
    components: { DragBar, Editor, item: FormItem },
    setup() {
        return {
            ...createChangeHandlers(["x", "y", "mountx", "mounty", "w", "h"]),
        };
    },
};
