
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import DragBar from "./form/DragBar.vue";
import { createChangeHandlers } from "./form/utils";

export default {
    components: { Editor, item: FormItem, DragBar },
    setup() {
        return {
            ...createChangeHandlers(["x", "y", "scalex", "scaley", "pivotx", "pivoty", "rotation"]),
        };
    },
};
