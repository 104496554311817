
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import ColorDragBar from "./form/ColorDragBar.vue";
import { createChangeHandlers } from "./form/utils";

export default {
    components: { ColorDragBar, Editor, item: FormItem },
    setup() {
        return {
            ...createChangeHandlers(["colorLeft", "colorRight"]),
        };
    },
};
