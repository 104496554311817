
import { ref, Ref, watch, SetupContext, computed } from "vue";
import { VugelMouseEvent, Node } from "vugel";

export default {
    props: {
        initialValue: { type: Boolean, default: false },
    },
    setup(props: any, context: SetupContext) {
        const value = ref(false);

        const click = (e: VugelMouseEvent) => {
            value.value = !value.value;
        };

        watch(value, (v: boolean) => {
            context.emit("change", { value: value.value });
        });

        value.value = props.initialValue;

        const circleX = computed(() => {
            return value.value ? 30 : 10;
        });

        return {
            value,
            click,
            circleX,
        };
    },
};
