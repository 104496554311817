
import { ref, Ref } from "vue";
import { VugelMouseEvent, Node } from "vugel";
import Editor from "./form/Editor.vue";
import { createChangeHandlers } from "./form/utils";
import Toggle from "./form/Toggle.vue";
import FormItem from "./form/FormItem.vue";

export default {
    components: { Editor, Toggle, item: FormItem },
    setup() {
        const dragTarget: Ref<Node | null> = ref(null);

        let startEvent: VugelMouseEvent | null = null;
        let startX = 0;
        let startY = 0;
        const start = (e: VugelMouseEvent) => {
            if (dragTarget.value) {
                startEvent = e;

                // As Vugel doesn't expose the properties, we refer directly to the element.
                startX = dragTarget.value.el.x as number;
                startY = dragTarget.value.el.y as number;
            }
        };

        const move = (e: VugelMouseEvent) => {
            if (startEvent) {
                const deltaX = e.canvasOffsetX - startEvent.canvasOffsetX;
                const deltaY = e.canvasOffsetY - startEvent.canvasOffsetY;
                dragTarget.value!.x = startX + deltaX;
                dragTarget.value!.y = startY + deltaY;

                // Do not scroll on mobile.
                e.originalEvent.preventDefault();
            }
        };

        const end = (e: VugelMouseEvent) => {
            startEvent = null;
        };

        return {
            start,
            move,
            end,
            dragTarget,
            ...createChangeHandlers(["clipping"]),
        };
    },
};
