<template compiler="vugel">
    <paragraph :x="20" :y="20" func-w="w-40" func-h="h-40" :font-size="40" font-color="red">
        The paragraph has the ability to wrap text. Drag the width of the window to see it in action.
    </paragraph>
</template>

<script lang="ts">
export default {
};
</script>
