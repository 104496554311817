
import { ref, watch, Ref } from "vue";
import { DrawingFunctionOptions } from "tree2d";
import DragBar from "./form/DragBar.vue";
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import { createChangeHandlers } from "./form/utils";
import { Drawing } from "vugel";

export default {
    components: { DragBar, Editor, item: FormItem },
    setup() {
        const handlers = createChangeHandlers(["gradient", "w", "h"]);

        const drawing: Ref<Drawing | null> = ref(null);

        // Make sure that the drawing is updated after a change.
        watch(handlers.gradient, () => {
            if (drawing.value) {
                drawing.value.update();
            }
        });

        return {
            drawing,
            draw(info: DrawingFunctionOptions) {
                const ctx = info.context;
                ctx.beginPath();
                const rectangle = new Path2D();
                rectangle.rect(0, 0, info.w, info.h);

                const gradient = ctx.createLinearGradient(0, 0, handlers.gradient.value, 0);
                gradient.addColorStop(0, "magenta");
                gradient.addColorStop(0.5, "blue");
                gradient.addColorStop(1.0, "red");

                ctx.strokeStyle = gradient;
                ctx.lineWidth = 20;
                ctx.stroke(rectangle);
                return {};
            },
            ...handlers,
        };
    },
};
