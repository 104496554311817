
import DragBar from "./form/DragBar.vue";
import Editor from "./form/Editor.vue";
import FormItem from "./form/FormItem.vue";
import { createChangeHandlers } from "./form/utils";
import Toggle from "./form/Toggle.vue";

export default {
    components: { DragBar, Toggle, Editor, item: FormItem },
    setup() {
        return {
            ...createChangeHandlers(["alpha", "visible"]),
        };
    },
};
